import { assign, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Col, Row } from '../grid';
import { imageTransform } from '../../content/transforms';

const CardListing = ({
  cardAlignment,
  cardBackgroundColor,
  CardComponent,
  cardWidth,
  cardImageHeight,
  imageTransformIsRequired,
  categoryName,
  items,
  onClick,
  imageDimensions,
  shouldKeepVertical,
}) => (
  <div>
    <Row flexWrap="wrap" justifyContent={cardAlignment}>
      {items.map(item => {
        const imageArray = item.image;

        assign(imageArray[0], imageDimensions);

        return (
          <Col p="1rem" key={item.heading || item.id} width={cardWidth}>
            <CardComponent
              isFullWidth
              shouldKeepVertical={shouldKeepVertical}
              backgroundColor={cardBackgroundColor}
              variant={cardBackgroundColor}
              item={{
                ...item,
                categoryName: categoryName ?? item.productCategory?.name,
                formattedDietaryPreferencesList: get(item, 'formattedDietaryPreferencesList'),
                formattedKilojoules: get(item, 'formattedKilojoules'),
                hasOtherSizes: get(item, 'hasOtherSizes'),
                hasVariablePrice: get(item, 'hasVariablePrice'),
                isAvailable: get(item, 'isAnySizeAvailable'),
                imageAlt: get(item, 'name'),
                image: imageTransformIsRequired ? imageTransform(imageArray) : imageArray,
                plu: item.plu,
                hasOtherSizeEligibleForDeliveryDiscount: get(item, 'hasOtherSizeEligibleForDeliveryDiscount'),
              }}
              onClick={onClick ? () => onClick(item) : undefined}
              imageHeight={cardImageHeight}
              cardWidth={cardWidth}
            />
          </Col>
        );
      })}
    </Row>
  </div>
);

CardListing.propTypes = {
  cardAlignment: PropTypes.oneOf(['center', 'left', 'right']),
  cardBackgroundColor: PropTypes.oneOf(['light', 'dark', 'transparent']),
  CardComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  cardWidth: StyledSystemPropTypes.layout.width,
  cardImageHeight: StyledSystemPropTypes.layout.height,
  imageTransformIsRequired: PropTypes.bool,
  categoryName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func,
  imageDimensions: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  shouldKeepVertical: PropTypes.bool,
};

CardListing.defaultProps = {
  cardBackgroundColor: 'dark',
  cardWidth: { xs: 12 / 12, md: 4 / 12 },
  imageTransformIsRequired: false,
  onClick: undefined,
  cardAlignment: 'left',
  cardImageHeight: {
    xs: '200px',
    lg: '400px',
  },
  imageDimensions: undefined,
  shouldKeepVertical: true,
};

export { CardListing };
